<template>
    <div class="claim-row" @click="$emit('click', claim.user)">
        <div class="claim-row-date">
            {{ claim.date.seconds | timestamp2Date | dateformat('DD/MM/YYYY') }}
        </div>

        <div class="claim-row-name">
            <span class="truncate">{{
                claim.user ? claim.user.name : ''
            }}</span>
        </div>

        <div class="claim-row-provider">
            <span class="truncate">{{ claim.provider }}</span>
        </div>

        <div class="claim-row-invoice">
            <span class="truncate">{{ claim.invoice_number }}</span>
        </div>

        <div class="claim-row-created_by">
            <span class="truncate">{{
                claim.created_by ? claim.created_by.name : ''
            }}</span>
        </div>

        <template v-if="show_rejections && claim.rejected">
            <div class="claim-row-rejected-at">
                {{ claim.rejected.date | dateformat('DD/MM/YYYY') }}
            </div>

            <div class="claim-row-rejected-by">
                {{ claim.rejected.by }}
            </div>
        </template>
        <template v-if="show_rejections && !claim.rejected">
            <div class="claim-row-rejected-at">-</div>

            <div class="claim-row-rejected-by">-</div>
        </template>

        <div class="claim-row-total">
            <el-tag :disable-transitions="true" :type="'info'">
                {{ claim.total | currency }}
            </el-tag>
        </div>

        <template v-if="show_approvals">
            <div class="claim-row-approval-due">
                {{
                    claim.approval_due.seconds
                        | timestamp2Date
                        | dateformat('DD/MM/YYYY')
                }}
            </div>

            <div class="claim-row-approve" @click.stop>
                <approval-action :claim="claim" />
            </div>
        </template>

        <div class="claim-row-profile">
            <el-button type="primary" @click.stop="newClaim">
                Add claim
            </el-button>
        </div>
    </div>
</template>

<script>
import ApprovalAction from '@/views/components/ApprovalAction';

export default {
    name: 'claim-row',
    components: {ApprovalAction},
    props: {
        claim: {
            required: true,
            type: Object,
        },
        show_approvals: Boolean,
        show_rejections: Boolean,
    },
    methods: {
        newClaim() {
            this.$emit('new-claim', this.claim.user);
        },
        handleApproval(claim, status) {
            // record already updated, just update for UI
            this.claim.approval_status = status;
        },
    },
};
</script>

<style lang="scss" scoped>
.claim-row {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f0f2f5;
    background-color: white;
    transition: background-color 0.125s linear;
    cursor: pointer;
    &:hover {
        background-color: #f5f7fa;
    }

    > * {
        display: flex;
        align-items: center;
        border-right: 1px solid #f0f2f5;
        height: 100%;
        min-width: 100px;
        padding: 10px;
        color: #606266;
        font-size: 14px;
    }

    .claim-row-name,
    .claim-row-provider {
        flex: 1;
    }

    .claim-row-date {
        width: 100px;
    }
    .claim-row-approval-due {
        width: 130px;
    }

    .claim-row-invoice,
    .claim-row-total {
        width: 150px;
    }

    .claim-row-rejected-at,
    .claim-row-rejected-by {
        width: 120px;
    }

    .claim-row-approve {
        min-width: 0;
        width: 100px;
        font-size: 20px;
        justify-content: center;
    }

    .claim-row-created_by {
        width: 120px;
    }

    .claim-row-profile {
        width: 150px;

        > * {
            width: 100%;
        }
    }

    .truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.el-tag {
    width: 100%;
    text-align: center;
}
</style>
