var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "claim-list-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "claim-list",
        class: { scroll: _vm.scroll },
        style: _vm.css,
      },
      [
        _c(
          "div",
          { staticClass: "header" },
          [
            _c(
              "div",
              {
                staticClass: "header-col sortable date",
                on: {
                  click: function ($event) {
                    return _vm.sortBy("date")
                  },
                },
              },
              [
                _vm._v(" Date "),
                _c("column-sort-icon", {
                  attrs: { column: "date", sort: _vm.sort },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "header-col sortable name",
                on: {
                  click: function ($event) {
                    return _vm.sortBy("name")
                  },
                },
              },
              [
                _vm._v(" Name "),
                _c("column-sort-icon", {
                  attrs: { column: "name", sort: _vm.sort },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "header-col sortable provider",
                on: {
                  click: function ($event) {
                    return _vm.sortBy("provider")
                  },
                },
              },
              [
                _vm._v(" Provider "),
                _c("column-sort-icon", {
                  attrs: { column: "provider", sort: _vm.sort },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "header-col sortable invoice",
                on: {
                  click: function ($event) {
                    return _vm.sortBy("invoice_number")
                  },
                },
              },
              [
                _vm._v(" Invoice # "),
                _c("column-sort-icon", {
                  attrs: { column: "invoice_number", sort: _vm.sort },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "header-col sortable created_by",
                on: {
                  click: function ($event) {
                    return _vm.sortBy("created_by")
                  },
                },
              },
              [
                _vm._v(" Created by "),
                _c("column-sort-icon", {
                  attrs: { column: "created_by", sort: _vm.sort },
                }),
              ],
              1
            ),
            _vm.show_rejections
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "header-col rejected_at sortable",
                      on: {
                        click: function ($event) {
                          return _vm.sortBy("rejected_at")
                        },
                      },
                    },
                    [
                      _vm._v(" Rejected at "),
                      _c("column-sort-icon", {
                        attrs: { column: "rejected_at", sort: _vm.sort },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "header-col rejected_by sortable",
                      on: {
                        click: function ($event) {
                          return _vm.sortBy("rejected_by")
                        },
                      },
                    },
                    [
                      _vm._v(" Rejected by "),
                      _c("column-sort-icon", {
                        attrs: { column: "rejected_by", sort: _vm.sort },
                      }),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "header-col sortable total",
                on: {
                  click: function ($event) {
                    return _vm.sortBy("total")
                  },
                },
              },
              [
                _vm._v(" Total "),
                _c("column-sort-icon", {
                  attrs: { column: "total", sort: _vm.sort },
                }),
              ],
              1
            ),
            _vm.show_approvals
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "header-col approval-due sortable",
                      on: {
                        click: function ($event) {
                          return _vm.sortBy("approval_due")
                        },
                      },
                    },
                    [
                      _vm._v(" Approval due "),
                      _c("column-sort-icon", {
                        attrs: { column: "approval_due", sort: _vm.sort },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "header-col approve" }, [
                    _vm._v("Approve"),
                  ]),
                ]
              : _vm._e(),
            _c("div", { staticClass: "header-col action" }),
          ],
          2
        ),
        _vm.claims.length
          ? _c(
              "div",
              { staticClass: "claim-list-body" },
              _vm._l(_vm.claims, function (claim) {
                return _c("claim-row", {
                  key: claim.id,
                  attrs: {
                    claim: claim,
                    show_approvals: _vm.show_approvals,
                    show_rejections: _vm.show_rejections,
                  },
                  on: {
                    click: (user) =>
                      _vm.$router.push(`/user/${user.id}?claim_id=${claim.id}`),
                    "new-claim": _vm.newClaim,
                  },
                })
              }),
              1
            )
          : _c("div", { staticClass: "no-claims" }, [_vm._v("No data")]),
      ]
    ),
    _c("div", { staticClass: "claim-list-footer" }, [
      _vm._v(
        " " +
          _vm._s(_vm._f("pluralize")(_vm.claims.length, " claim")) +
          " found "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }