<template>
    <div class="claim-list-wrapper">
        <div class="claim-list" :class="{scroll}" :style="css">
            <div class="header">
                <div class="header-col sortable date" @click="sortBy('date')">
                    Date
                    <column-sort-icon column="date" :sort="sort" />
                </div>
                <div class="header-col sortable name" @click="sortBy('name')">
                    Name
                    <column-sort-icon column="name" :sort="sort" />
                </div>
                <div
                    class="header-col sortable provider"
                    @click="sortBy('provider')"
                >
                    Provider
                    <column-sort-icon column="provider" :sort="sort" />
                </div>
                <div
                    class="header-col sortable invoice"
                    @click="sortBy('invoice_number')"
                >
                    Invoice #
                    <column-sort-icon column="invoice_number" :sort="sort" />
                </div>
                <div
                    class="header-col sortable created_by"
                    @click="sortBy('created_by')"
                >
                    Created by
                    <column-sort-icon column="created_by" :sort="sort" />
                </div>
                <template v-if="show_rejections">
                    <div
                        class="header-col rejected_at sortable"
                        @click="sortBy('rejected_at')"
                    >
                        Rejected at
                        <column-sort-icon column="rejected_at" :sort="sort" />
                    </div>
                    <div
                        class="header-col rejected_by sortable"
                        @click="sortBy('rejected_by')"
                    >
                        Rejected by
                        <column-sort-icon column="rejected_by" :sort="sort" />
                    </div>
                </template>
                <div class="header-col sortable total" @click="sortBy('total')">
                    Total
                    <column-sort-icon column="total" :sort="sort" />
                </div>
                <template v-if="show_approvals">
                    <div
                        class="header-col approval-due sortable"
                        @click="sortBy('approval_due')"
                    >
                        Approval due
                        <column-sort-icon column="approval_due" :sort="sort" />
                    </div>
                    <div class="header-col approve">Approve</div>
                </template>
                <div class="header-col action"></div>
            </div>
            <div v-if="claims.length" class="claim-list-body">
                <claim-row
                    v-for="claim in claims"
                    :key="claim.id"
                    :claim="claim"
                    :show_approvals="show_approvals"
                    :show_rejections="show_rejections"
                    @click="
                        (user) =>
                            $router.push(
                                `/user/${user.id}?claim_id=${claim.id}`
                            )
                    "
                    @new-claim="newClaim"
                />
            </div>
            <div v-else class="no-claims">No data</div>
        </div>
        <div class="claim-list-footer">
            {{ claims.length | pluralize(' claim') }} found
        </div>
    </div>
</template>

<script>
import ClaimRow from '@/views/components/ClaimRow';
import ColumnSortIcon from '@/views/components/ColumnSortIcon';

export default {
    name: 'claim-list',
    components: {
        ClaimRow,
        ColumnSortIcon,
    },
    props: {
        claims: {
            required: true,
            type: Array,
        },
        css: {
            type: Object,
            required: false,
            default: null,
        },
        sort: {
            type: Object,
            required: true,
        },
        scroll: Boolean,
        show_approvals: {
            type: Boolean,
            default: false,
        },
        show_rejections: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        sortBy(column) {
            this.$emit('sort-by', column);
        },
        newClaim(user) {
            this.$emit('new-claim', user);
        },
    },
};
</script>

<style lang="scss" scoped>
.claim-list {
    background: white;
    min-height: 300px;

    .header {
        display: flex;
        width: 100%;
        height: 50px;
        border-bottom: 1px solid #f0f2f5;

        .header-col {
            display: flex;
            align-items: center;
            border-right: 1px solid #f0f2f5;
            padding: 10px;
            font-size: 14px;
            color: #909399;
            font-weight: 600;

            &.name,
            &.provider {
                flex: 1;
            }

            &.date {
                width: 100px;
            }
            &.approval-due {
                width: 130px;
            }

            &.invoice,
            &.total {
                width: 150px;
            }

            &.approve {
                width: 100px;
                justify-content: center;
            }

            &.rejected_at {
                width: 120px;
            }
            &.rejected_by {
                width: 120px;
            }

            &.created_by {
                width: 120px;
            }

            &.action {
                width: 150px;
            }

            &.sortable {
                cursor: pointer;
                &:hover {
                    background: #fafafa;
                }
            }
        }
    }

    &.scroll {
        position: relative;

        .claim-list-body {
            overflow-y: auto;
            height: calc(100% - 50px);
            padding-bottom: 50px;
            position: relative;
        }

        &::after {
            pointer-events: none;
            content: '';
            height: 80px;
            width: 100%;
            background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 0) 100%
            );
            position: absolute;
            bottom: 0;
        }
    }
}

.claim-list-footer {
    opacity: 0.75;
    margin-top: 15px;
    font-size: 14px;
    text-align: center;
}

.no-claims {
    width: 100%;
    line-height: 50px;
    color: #606266;
    text-align: center;
}
</style>

<style lang="scss">
@import '../../assets/scss/vars';

.claim-list {
    .sortable:hover {
        .column-sort i {
            color: $purple-rain;
        }
    }
}
</style>
