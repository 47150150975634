<template>
    <span v-if="sort.column === column" class="column-sort active">
        <i v-if="sort.dir === 'asc'" class="el-icon-bottom" />
        <i v-else class="el-icon-top" />
    </span>
    <span v-else class="column-sort">
        <i class="el-icon-sort" />
    </span>
</template>

<script>
export default {
    name: 'column-sort-icon',
    props: {
        column: {
            type: String,
            required: true,
        },
        sort: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.column-sort {
    margin-left: 5px;
    &.active i {
        font-weight: bold;
    }
}
</style>
