var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.sort.column === _vm.column
    ? _c("span", { staticClass: "column-sort active" }, [
        _vm.sort.dir === "asc"
          ? _c("i", { staticClass: "el-icon-bottom" })
          : _c("i", { staticClass: "el-icon-top" }),
      ])
    : _c("span", { staticClass: "column-sort" }, [
        _c("i", { staticClass: "el-icon-sort" }),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }