var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "claim-row",
      on: {
        click: function ($event) {
          return _vm.$emit("click", _vm.claim.user)
        },
      },
    },
    [
      _c("div", { staticClass: "claim-row-date" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm._f("dateformat")(
                _vm._f("timestamp2Date")(_vm.claim.date.seconds),
                "DD/MM/YYYY"
              )
            ) +
            " "
        ),
      ]),
      _c("div", { staticClass: "claim-row-name" }, [
        _c("span", { staticClass: "truncate" }, [
          _vm._v(_vm._s(_vm.claim.user ? _vm.claim.user.name : "")),
        ]),
      ]),
      _c("div", { staticClass: "claim-row-provider" }, [
        _c("span", { staticClass: "truncate" }, [
          _vm._v(_vm._s(_vm.claim.provider)),
        ]),
      ]),
      _c("div", { staticClass: "claim-row-invoice" }, [
        _c("span", { staticClass: "truncate" }, [
          _vm._v(_vm._s(_vm.claim.invoice_number)),
        ]),
      ]),
      _c("div", { staticClass: "claim-row-created_by" }, [
        _c("span", { staticClass: "truncate" }, [
          _vm._v(_vm._s(_vm.claim.created_by ? _vm.claim.created_by.name : "")),
        ]),
      ]),
      _vm.show_rejections && _vm.claim.rejected
        ? [
            _c("div", { staticClass: "claim-row-rejected-at" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("dateformat")(_vm.claim.rejected.date, "DD/MM/YYYY")
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "claim-row-rejected-by" }, [
              _vm._v(" " + _vm._s(_vm.claim.rejected.by) + " "),
            ]),
          ]
        : _vm._e(),
      _vm.show_rejections && !_vm.claim.rejected
        ? [
            _c("div", { staticClass: "claim-row-rejected-at" }, [_vm._v("-")]),
            _c("div", { staticClass: "claim-row-rejected-by" }, [_vm._v("-")]),
          ]
        : _vm._e(),
      _c(
        "div",
        { staticClass: "claim-row-total" },
        [
          _c(
            "el-tag",
            { attrs: { "disable-transitions": true, type: "info" } },
            [_vm._v(" " + _vm._s(_vm._f("currency")(_vm.claim.total)) + " ")]
          ),
        ],
        1
      ),
      _vm.show_approvals
        ? [
            _c("div", { staticClass: "claim-row-approval-due" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("dateformat")(
                      _vm._f("timestamp2Date")(_vm.claim.approval_due.seconds),
                      "DD/MM/YYYY"
                    )
                  ) +
                  " "
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "claim-row-approve",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [_c("approval-action", { attrs: { claim: _vm.claim } })],
              1
            ),
          ]
        : _vm._e(),
      _c(
        "div",
        { staticClass: "claim-row-profile" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.newClaim.apply(null, arguments)
                },
              },
            },
            [_vm._v(" Add claim ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }